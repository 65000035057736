import { all, put, takeLatest, call } from 'redux-saga/effects'

import {
  setFrameworkEnabled,
  deleteControlGroup,
  deletePolicy,
} from 'containers/ControlFrameworkData/sagas'
import { bulkOperationSuccess } from './actions'
import {
  BULK_DELETE_CONTROL_GROUPS,
  BULK_DISABLE_CONTROL_GROUPS,
  BULK_ENABLE_CONTROL_GROUPS,
  BULK_DELETE_POLICIES,
} from './constants'

function* bulkDeletePolicies({ payload }) {
  //payload = arr of srns to delete
  yield all(payload.map(srn => call(deletePolicy, { payload: srn })))
  yield put(bulkOperationSuccess())
}

function* bulkDisableFrameworks({ payload }) {
  const controlGroupIds = payload

  yield all(
    controlGroupIds.map(id =>
      call(setFrameworkEnabled, { payload: { srn: id, enabled: false } })
    )
  )

  yield put(bulkOperationSuccess())
}

function* bulkDeleteFrameworks({ payload }) {
  const controlGroupIds = payload

  yield all(
    controlGroupIds.map(id => call(deleteControlGroup, { payload: id }))
  )

  yield put(bulkOperationSuccess())
}

function* bulkEnableFrameworks({ payload }) {
  const controlGroupIds = payload

  yield all(
    controlGroupIds.map(id =>
      call(setFrameworkEnabled, { payload: { srn: id, enabled: true } })
    )
  )

  yield put(bulkOperationSuccess())
}

function* ControlCenterSaga() {
  yield all([
    takeLatest(BULK_DELETE_CONTROL_GROUPS, bulkDeleteFrameworks),
    takeLatest(BULK_DISABLE_CONTROL_GROUPS, bulkDisableFrameworks),
    takeLatest(BULK_ENABLE_CONTROL_GROUPS, bulkEnableFrameworks),
    takeLatest(BULK_DELETE_POLICIES, bulkDeletePolicies),
  ])
}

export default ControlCenterSaga
