/*
 *
 * ControlCenter actions
 *
 */

import {
  BULK_DELETE_CONTROL_GROUPS,
  BULK_DISABLE_CONTROL_GROUPS,
  BULK_ENABLE_CONTROL_GROUPS,
  BULK_OPERATION_SUCCESS,
  BULK_DELETE_POLICIES,
} from './constants'
import { createAction } from 'redux-actions'

export const bulkDeleteControlGroups = createAction(BULK_DELETE_CONTROL_GROUPS)
export const bulkDisableControlGroups = createAction(
  BULK_DISABLE_CONTROL_GROUPS
)
export const bulkOperationSuccess = createAction(BULK_OPERATION_SUCCESS)
export const bulkEnableControlGroups = createAction(BULK_ENABLE_CONTROL_GROUPS)

export const bulkDeletePolicies = createAction(BULK_DELETE_POLICIES)
