import { defineMessages } from 'react-intl'

export default defineMessages({
  createCGHeader: {
    id: 'app.containers.ControlCenter.createCGHeader',
    defaultMessage: 'Create Control Framework',
  },
  loadCGHeader: {
    id: 'app.containers.ControlCenter.loadCGHeader',
    defaultMessage: 'Load from Library',
  },
  cGTitleLabel: {
    id: 'app.containers.ControlCenter.cGTitleLabel',
    defaultMessage: 'Title',
  },
  cGDescriptionLabel: {
    id: 'app.containers.ControlCenter.cGDescriptionLabel',
    defaultMessage: 'Description',
  },
  cGFriendlyNameLabel: {
    id: 'app.containers.ControlCenter.cGFriendlyNameLabel',
    defaultMessage: 'Short Name',
  },
  cancelButton: {
    id: 'app.containers.ControlCenter.cancelButton',
    defaultMessage: 'Cancel',
  },
  closeButton: {
    id: 'app.containers.ControlCenter.closeButton',
    defaultMessage: 'Close',
  },
  filterButton: {
    id: 'app.containers.ControlCenter.filterButton',
    defaultMessage: 'Filter Control Frameworks',
  },
  createButton: {
    id: 'app.containers.ControlCenter.createButton',
    defaultMessage: 'Create',
  },
  deleteButton: {
    id: 'app.containers.ControlCenter.deleteButton',
    defaultMessage: 'Delete',
  },
  emptyGroups: {
    id: 'app.containers.ControlCenter.emptyGroups',
    defaultMessage: 'No Control Frameworks',
  },
  overviewTitle: {
    id: 'app.containers.ControlCenter.overviewTitle',
    defaultMessage: 'Overview',
  },
  controlGroupsTitle: {
    id: 'app.containers.ControlCenter.controlGroupsTitle',
    defaultMessage: 'Control Frameworks',
  },
  allPolicyTitle: {
    id: 'app.containers.ControlCenter.allPolicyTitle',
    defaultMessage: 'All Policies',
  },
  filterControlGroupsHint: {
    id: 'app.containers.ControlCenter.filterControlGroupsHint',
    defaultMessage: 'Filter control frameworks...',
  },
  createControlGroupHint: {
    id: 'app.containers.ControlCenter.createControlGroupHint',
    defaultMessage: 'Create a control framework',
  },
  createControlGroupButton: {
    id: 'app.containers.ControlCenter.createControlGroupButton',
    defaultMessage: 'Create Framework',
  },
  viewAllPoliciesLink: {
    id: 'app.containers.ControlCenter.viewAllPoliciesLink',
    defaultMessage: 'View All Policies',
  },
  cfInstructions1: {
    id: 'app.containers.ControlCenter.cfInstructions1',
    defaultMessage:
      'Control Frameworks combine powerful searches with alerting thresholds.',
  },
  cfInstructions2: {
    id: 'app.containers.ControlCenter.cfInstructions2',
    defaultMessage: 'Load from the content library, or craft your own.',
  },
})
