import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { List, Map, fromJS } from 'immutable'
import qs from 'query-string'
import { push } from 'connected-react-router'

import BorderedCard from 'components/BorderedCard'
import DataTable from 'components/DataTable'
import permissionChecker from 'containers/PermissionChecker'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import StartMessage from 'components/StartMessage'
import { ToolbarItem } from 'components/BulkActionToolbar'
import TextLink from 'components/TextLink'
import Icon from 'components/Icon'
import Button from 'components/Button'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import {
  selectPolicies,
  selectControlGroups,
  selectLoadedPolicies,
} from 'containers/ControlFrameworkData/selectors'
import SectionHeader from 'components/SectionHeader'
import {
  selectSonraiUsers,
  selectSwimlanes,
} from 'containers/SonraiData/selectors'

import {
  bulkDeleteControlGroups,
  bulkEnableControlGroups,
  bulkDisableControlGroups,
} from './actions'
import { selectBulkEditing } from './selectors'
import AddControlGroupModal from './AddControlGroupModal'
import ControlFrameworkFilter from './ControlFrameworkFilter'
import messages from './messages'
import CreatedByBadge from 'components/CreatedByBadge'

import { displayAsEnabled } from './utils'
import { stripTags } from 'utils/sonraiUtils'

const ENABLED_FILTER_MODE = { ENABLED: 1, DISABLED: -1, ALL: 0 }
export class ControlFrameworksManager extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      header: {
        paddingBottom: '1em',
        display: 'grid',
        gridTemplateColumns: '1fr auto auto',
        gridColumnGap: '1em',
        alignItems: 'center',
      },
      instructions: {
        textAlign: 'center',
        color: props.theme.neutralDark,
        paddingTop: '4em',
      },
      emptyIcon: {
        color: props.theme.secondary,
      },
      groupHeader: {
        color: props.theme.neutralMedium,
        fontVariant: 'small-caps',
        fontWeight: '400',
        fontSize: '1.3em',
      },
      noActive: {
        color: props.theme.neutralMedium,
        textAlign: 'center',
      },
      filters: {
        display: 'flex',
        alignItems: 'center',
      },
    }

    this.state = {
      showCreateControlGroupModal: false,
      checkboxSelectedRows: [],
      filters: Map({
        swimlanes: null,
        enabled: null,
      }),
    }
  }

  setFilters = filters => {
    this.setState(prevState => {
      let newFilters = prevState.filters
      newFilters = newFilters
        .set('swimlanes', fromJS(filters.swimlanes))
        .set('enabled', fromJS(filters.enabled))
      return { filters: fromJS(newFilters) }
    })
  }

  clearFilters = () => {
    this.setState({
      filters: fromJS({ swimlanes: null, enabled: null }),
    })
  }

  toggleCreateControlGroupModal = () => {
    this.setState(currentState => ({
      showCreateControlGroupModal: !currentState.showCreateControlGroupModal,
    }))
  }

  deleteSelectedControlGroups = () => {
    this.props.bulkDeleteControlGroups(
      this.state.checkboxSelectedRows.map(cg => cg.srn)
    )

    this.setState({
      checkboxSelectedRows: [],
    })
  }

  navigateToCF = row => {
    this.props.push({
      pathname: '/App/ControlCenter/ControlGroup',
      search: qs.stringify({
        controlGroupId: row.srn,
      }),
    })
  }

  onLoad = ({ api }) => {
    this.gridApi = api
  }

  handleSelectionChange = evnt => {
    const rows = evnt.api.getSelectedRows() || []
    this.setState({ checkboxSelectedRows: rows })
  }

  // if control framework is a clone of another framework, take the framework with longest srn because it's newer
  // use srn UUID portion to determine if CF is the child of another CF
  getNoDupesBabyYaaAustinPowersVoice = () => {
    let bigGuys = Map()
    this.props.allControlGroups.forEach(cf => {
      if (cf.getIn(['cloneOf', 'items', 0, 'srn'], false)) {
        bigGuys = bigGuys.set(cf.getIn(['cloneOf', 'items', 0, 'srn']), true)
      }
    })
    return this.props.allControlGroups.toList().filter(cf => {
      const createdBy = this.props.sonraiUsers.get(cf.get('createdBy'))
      if (bigGuys.get(cf.get('srn')) && !createdBy) {
        return false
      } else {
        return true
      }
    })
  }

  checkInSwimlane = srns => {
    const cfSwimlanes = srns ? srns : []
    const swimlaneFilters = this.state.filters.get('swimlanes')
      ? this.state.filters
          .get('swimlanes')
          .map(s => s.get('value'))
          .toJS()
      : null
    if (!swimlaneFilters || swimlaneFilters.length < 1) return true

    for (let swombo of swimlaneFilters) {
      if (cfSwimlanes.includes(swombo)) return true
    }
    return false
  }

  checkEnabled = enabled => {
    if (!this.state.filters.get('enabled')) {
      return true
    }

    if (
      this.state.filters.getIn(['enabled', 'value']) ===
      ENABLED_FILTER_MODE.ENABLED
    ) {
      return enabled
    }

    if (
      this.state.filters.getIn(['enabled', 'value']) ===
      ENABLED_FILTER_MODE.DISABLED
    ) {
      return !enabled
    }
  }

  disableSelectedControlGroups = () => {
    this.props.bulkDisableControlGroups(
      this.state.checkboxSelectedRows.map(row => row.srn)
    )
    this.setState({ checkboxSelectedRows: [] })
  }

  enableSelectedControlGroups = () => {
    this.props.bulkEnableControlGroups(
      this.state.checkboxSelectedRows.map(row => row.srn)
    )
    this.setState({ checkboxSelectedRows: [] })
  }

  getSwimlaneStatus = (enabled, cf) => {
    if (!enabled) {
      return 'Disabled'
    }

    if (!cf.get('swimlaneSRNs') || cf.get('swimlaneSRNs').isEmpty()) {
      return 'Enabled globally'
    }

    if (cf.get('swimlaneSRNs').size === 1) {
      return 'Enabled on 1 swimlane'
    }

    return `Enabled on ${cf.get('swimlaneSRNs').size} swimlanes`
  }

  renderControlGroups = () => {
    if (this.props.allControlGroups.isEmpty()) {
      return (
        <StartMessage containerStyle={{ alignItems: 'start' }}>
          <div style={this.styles.instructions}>
            <p>
              <FormattedMessage {...messages.cfInstructions1} />
            </p>
            <div style={this.styles.emptyIcon}>
              <Icon
                fa
                name="exclamation-triangle-solid"
                transform="shrink-4 right-5 down-5"
              />
              <Icon fa name="clipboard-list-check" transform="grow-4" />
              <Icon
                fa
                name="check-circle-solid"
                transform="shrink-4 left-4 down-6"
              />
            </div>
            <br />
            <p>
              <FormattedMessage {...messages.cfInstructions2} />
            </p>
          </div>
        </StartMessage>
      )
    }

    const frameworks = this.getNoDupesBabyYaaAustinPowersVoice()
    const tableData = []

    frameworks.forEach(cf => {
      const createdByUser = this.props.sonraiUsers
        .get(cf.get('createdBy'), Map())
        .toJS()

      const enabled = displayAsEnabled(cf, frameworks, this.props.userData)
      if (
        this.checkInSwimlane(cf.get('swimlaneSRNs')) &&
        this.checkEnabled(cf.get('enabled'))
      ) {
        tableData.push({
          title: cf.get('title') || '',
          description: cf.get('description'),
          status: this.getSwimlaneStatus(enabled, cf),
          policies: cf.getIn(['contains', 'items'], List()).size,
          swimlanes: cf.get('swimlaneSRNs') ? cf.get('swimlaneSRNs').size : 0,
          enabled: enabled,
          lastModified: cf.get('lastModified'),
          createdBy: cf.get('createdBy'),
          createdByUser: createdByUser,
          srn: cf.get('srn'),
        })
      }
    })

    tableData.sort((a, b) =>
      a.title.localeCompare(b.title, undefined, { sensitivity: 'base' })
    )

    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.controlframeworks',
      resourceId: ({ org }) => `/org/${org}/`,
    })

    return (
      <div>
        <BorderedCard
          style={{
            height: '810px',
          }}
        >
          {frameworks.isEmpty() ? (
            <p style={this.styles.noActive}>
              There are no active Control Frameworks
            </p>
          ) : (
            <DataTable
              autosize={false}
              hasCheckBoxes={canEdit}
              pageSize={13}
              selectionChanged={
                canEdit ? this.handleSelectionChange : undefined
              }
              checkboxSelectedRows={
                canEdit ? this.state.checkboxSelectedRows : undefined
              }
              customFilter={() => (
                <div style={this.styles.filters}>
                  <ControlFrameworkFilter
                    setFilters={this.setFilters}
                    swimlanes={this.props.swimlanes}
                    filters={this.state.filters}
                    filterModes={ENABLED_FILTER_MODE}
                    clearFilters={this.clearFilters}
                  />
                </div>
              )}
              bulkActionWorking={this.props.bulkEditing}
              bulkActions={
                canEdit
                  ? [
                      <ToolbarItem
                        key="delete"
                        iconName="trash"
                        onClick={this.deleteSelectedControlGroups}
                      >
                        <FormattedMessage {...messages.deleteButton} />
                      </ToolbarItem>,
                      <ToolbarItem
                        key="disable"
                        iconName="ban"
                        onClick={this.disableSelectedControlGroups}
                      >
                        Disable
                      </ToolbarItem>,
                      <ToolbarItem
                        key="enable"
                        iconName="check-circle"
                        onClick={this.enableSelectedControlGroups}
                      >
                        Enable Globally
                      </ToolbarItem>,
                    ]
                  : undefined
              }
              onDoubleClickRow={this.navigateToCF}
              onLoad={this.onLoad}
              overlayLoadingTemplate='<span class="ag-overlay-loading-center">Working...</span>'
              data={tableData}
              expandCollapseColumn="description"
              customColumnConfig={{
                title: {
                  flex: 1,
                },
                srn: {
                  hide: true,
                },
                policies: {
                  width: 120,
                },
                swimlanes: {
                  width: 140,
                },
                enabled: {
                  hide: true,
                },
                createdByUser: {
                  hide: true,
                },
                status: {
                  minWidth: 200,
                  cellRendererFramework: ({ value, data }) => {
                    if (!data || !value) {
                      return null
                    }

                    if (value === 'Disabled') {
                      return (
                        <span
                          style={{
                            color: this.props.theme.neutralDark,
                            fontStyle: 'italic',
                            paddingRight: '1em',
                          }}
                        >
                          {value}
                        </span>
                      )
                    }

                    return (
                      <span style={{ color: this.props.theme.success }}>
                        {value}
                      </span>
                    )
                  },
                },
                createdBy: {
                  minWidth: 200,
                  width: 200,
                  valueGetter: params => {
                    if (!params.data) {
                      return ''
                    }

                    return params.data.createdByUser.name
                      ? stripTags(params.data.createdByUser.name)
                      : 'Sonrai'
                  },
                  cellRendererFramework: ({ data }) => {
                    if (!data) {
                      return null
                    }
                    return <CreatedByBadge table createdBy={data.createdBy} />
                  },
                },
              }}
            />
          )}
        </BorderedCard>
      </div>
    )
  }

  render() {
    return (
      <Fragment>
        {this.state.showCreateControlGroupModal && (
          <AddControlGroupModal toggle={this.toggleCreateControlGroupModal} />
        )}
        <div style={this.styles.header}>
          <SectionHeader>
            <FormattedMessage {...messages.controlGroupsTitle} />
          </SectionHeader>
          <div>
            <TextLink
              to={{ pathname: '/App/ControlCenter/ListPolicy' }}
              color="primary"
            >
              <FormattedMessage {...messages.viewAllPoliciesLink} />
            </TextLink>
          </div>
          <div>
            <WithPermission
              permissionName="edit.controlframeworks"
              resourceId={({ org }) => `/org/${org}/`}
            >
              <Button
                color="primary"
                title={this.props.intl.formatMessage(
                  messages.createControlGroupHint
                )}
                onClick={this.toggleCreateControlGroupModal}
              >
                <Icon fa name="plus" />{' '}
                <FormattedMessage {...messages.createControlGroupButton} />
              </Button>
            </WithPermission>
          </div>
        </div>
        {this.renderControlGroups()}
      </Fragment>
    )
  }
}

ControlFrameworksManager.propTypes = {
  bulkEditing: PropTypes.bool,
  bulkDeleteControlGroups: PropTypes.func,
  bulkDisableControlGroups: PropTypes.func,
  bulkEnableControlGroups: PropTypes.func,
  allControlGroups: ImmutablePropTypes.iterable.isRequired,
  intl: intlShape,
  push: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  userHasPermission: PropTypes.func.isRequired,
  sonraiUsers: ImmutablePropTypes.map.isRequired,
  swimlanes: ImmutablePropTypes.map,
  theme: themeShape,
}

const mapStateToProps = createStructuredSelector({
  bulkEditing: selectBulkEditing,
  allControlGroups: selectControlGroups,
  policies: selectPolicies,
  policiesLoaded: selectLoadedPolicies,
  sonraiUsers: selectSonraiUsers,
  swimlanes: selectSwimlanes,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      bulkDeleteControlGroups,
      bulkEnableControlGroups,
      bulkDisableControlGroups,
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  withConnect,
  permissionChecker,
  themeable,
  injectIntl
)(ControlFrameworksManager)
