import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import Button from 'components/Button'
import Popover, { PopoverBody, PopoverAnchor } from 'components/Popover'
import Icon from 'components/Icon'
import Select from 'react-select'
import TextLink from 'components/TextLink'

export class ControlFrameworkFilter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      swimlanes: undefined,
      enabled: undefined,
    }

    this.styles = {
      filterList: {
        gridArea: 'filterList',
        paddingTop: '0.75em',
        paddingLeft: '1em',
        fontFamily: 'Roboto-Italic',
        fontSize: '14px',
        color: '#777777',
      },
      sort: {
        marginRight: '0.5em',
      },
      filter: {
        marginLeft: '0.5em',
      },
      filterTitle: {
        marginBottom: '0.5em',
        display: 'block',
      },
      filterHeader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0.25rem 0.5rem 0.25rem 0.5rem',
      },
      filterPanelFooter: {
        width: '100%',
        margin: '1rem 0rem 0rem 0rem',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      },
    }
  }

  handleEnabledStateChange = params => {
    this.setState({ enabled: params })
  }

  handleSwimlaneFilterChange = params => {
    this.setState({ swimlanes: params })
  }

  toggleFilterBubble = () => {
    this.setState(prevState => ({
      filterMenuExpanded: !prevState.filterMenuExpanded,
      swimlanes: undefined,
      enabled: undefined,
    }))
  }

  applyFilters = () => {
    this.props.setFilters({
      swimlanes: this.state.swimlanes,
      enabled: this.state.enabled,
    })
    this.setState({
      filterMenuExpanded: false,
    })
  }

  filtersEmpty = () => {
    return (
      !this.props.filters.get('swimlanes') && !this.props.filters.get('enabled')
    )
  }

  clearFilters = () => {
    this.toggleFilterBubble()
    this.setState({ swimlanes: undefined, enabled: undefined })
    this.props.clearFilters()
  }

  getSwimlaneOptions = () =>
    this.props.swimlanes
      ? this.props.swimlanes
          .map(lane => ({
            value: lane.get('srn'),
            label: lane.get('title'),
          }))
          .toList()
          .toJS()
      : []

  getEnableOptions = () => [
    { value: this.props.filterModes.ENABLED, label: 'Enabled' },
    { value: this.props.filterModes.DISABLED, label: 'Disabled' },
  ]

  render() {
    const filters = this.props.filters.toJS()

    return (
      <Popover
        isOpen={this.state.filterMenuExpanded}
        onToggle={this.toggleFilterBubble}
      >
        <PopoverAnchor>
          {this.filtersEmpty() ? (
            <Icon style={this.styles.filter} fa name="filter" />
          ) : (
            <Icon
              style={{
                ...this.styles.filter,
                color: this.props.theme.emphasis,
              }}
              fa
              name="filter-solid"
            />
          )}
        </PopoverAnchor>
        <PopoverBody>
          <div style={{ width: '350px' }}>
            <div style={this.styles.filterHeader}>
              <div
                style={{
                  fontSize: '1.15rem',
                  fontWeight: '400',
                }}
              >
                Filters
              </div>
              <TextLink
                onClick={this.clearFilters}
                color="primary"
                style={{
                  cursor: 'pointer',
                }}
              >
                Clear All Filters
              </TextLink>
            </div>

            <div style={{ margin: '0.5em' }}>
              <span style={this.styles.filterTitle}>Enabled on Swimlanes:</span>
              <Select
                placeholder="Choose Swimlanes..."
                onChange={this.handleSwimlaneFilterChange}
                value={
                  this.state.swimlanes === undefined
                    ? filters.swimlanes
                    : this.state.swimlanes
                }
                isMulti
                isClearable
                options={this.getSwimlaneOptions()}
              />
            </div>
            <div style={{ margin: '0.5em' }}>
              <span style={this.styles.filterTitle}>Enabled State:</span>
              <Select
                onChange={this.handleEnabledStateChange}
                placeholder={`All States`}
                value={
                  this.state.enabled === undefined
                    ? filters.enabled
                    : this.state.enabled
                }
                isClearable
                options={this.getEnableOptions()}
              />
            </div>
          </div>
          <div style={this.styles.filterPanelFooter}>
            <TextLink
              onClick={this.toggleFilterBubble}
              color="primary"
              style={{
                cursor: 'pointer',
                paddingRight: '1rem',
              }}
            >
              Cancel
            </TextLink>
            <Button color="primary" onClick={this.applyFilters}>
              Apply
            </Button>
          </div>
        </PopoverBody>
      </Popover>
    )
  }
}

ControlFrameworkFilter.propTypes = {
  filters: ImmutablePropTypes.map,
  clearFilters: PropTypes.func,
  setFilters: PropTypes.func,
  filterModes: PropTypes.object,
  swimlanes: ImmutablePropTypes.map,
  theme: themeShape,
}

export default themeable(ControlFrameworkFilter)
