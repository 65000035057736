import {
  FETCH_CONTENT,
  SET_CONTENT,
  CLEAR_CONTENT,
  SET_ACTIVE_FRAMEWORK,
} from './constants'
import { createAction } from 'redux-actions'

export const fetchContent = createAction(FETCH_CONTENT)
export const setContent = createAction(SET_CONTENT)
export const clearContent = createAction(CLEAR_CONTENT)
export const setActiveFramework = createAction(SET_ACTIVE_FRAMEWORK)
