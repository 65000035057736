import React from 'react'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import ImmutablePropTypes from 'react-immutable-proptypes'
import {
  selectPolicies,
  selectCreatingPolicy,
} from 'containers/ControlFrameworkData/selectors'
import { selectSonraiUsers } from 'containers/SonraiData/selectors'
import { selectBulkEditing } from './selectors'
import DataTable from 'components/DataTable'
import CreatedByBadge from 'components/CreatedByBadge'
import Breadcrumb, { BreadcrumbItem } from 'components/Breadcrumb'
import BorderedCard from 'components/BorderedCard'
import TextLink from 'components/TextLink'
import Button from 'components/Button'
import Icon from 'components/Icon'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { createPolicy } from 'containers/ControlFrameworkData/actions'
import { bulkDeletePolicies } from './actions'
import { push } from 'connected-react-router'
import CreatePolicyModal from './CreatePolicyModal'
import {
  selectSonraiSearches,
  selectSavedSearches,
} from 'containers/SonraiData/selectors'
import qs from 'query-string'
import { SEARCH_VIEWS } from 'containers/Search/constants'
import permissionChecker from 'containers/PermissionChecker'
import { ToolbarItem } from 'components/ActionToolbar'

class PolicyList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      createModalOpen: false,
      checkboxSelectedRows: [],
    }
    this.styles = {
      navContextContainer: {
        height: '24px',
        gridArea: 'breadcrumbs',
      },
      container: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'auto 45px 1fr',
        gridTemplateAreas: '"breadcrumbs" "title" "policies"',
        gridColumnGap: '1em',
        gridRowGap: '0.5em',
        padding: '1em',
        height: '100%',
        overflow: 'auto',
      },
      titleContainer: {
        gridArea: 'title',
        display: 'grid',
        gridTemplateColumns: '1fr auto',
      },
      pageTitle: {
        fontSize: '1.5em',
      },
      policyContainer: {
        gridArea: 'policies',
      },
    }
  }

  closeCreateModal = () => {
    this.setState({ createModalOpen: false })
  }

  openCreateModal = () => {
    this.setState({ createModalOpen: true })
  }

  navigateToPolicy = srn => {
    this.props.push({
      pathname: '/App/ControlCenter/Policy',
      search: qs.stringify({
        policyId: srn,
      }),
    })
  }

  handleCellClick = params => {
    if (params.column.colId === 'search') {
      this.navigateToSearch(params.data.searchSrn)
    } else {
      this.navigateToPolicy(params.data.policySrn)
    }
  }

  navigateToSearch = srn => {
    this.props.push({
      pathname: '/App/Search',
      search: qs.stringify({
        searchId: srn,
        view: SEARCH_VIEWS.BUILD,
      }),
    })
  }

  formatPolicies = () =>
    this.props.policies
      .toList()
      .map(policy => {
        const createdByUser = this.props.sonraiUsers
          .get(policy.get('createdBy'), Map())
          .toJS()
        return {
          title: policy.get('title'),
          alertingLevel: policy.get('alertingLevelNumeric'),
          description: policy.get('description'),
          search: policy.getIn(['contains', 'items', 0, 'name'], '-'),
          policySrn: policy.get('srn'),
          searchSrn: policy.getIn(['contains', 'items', 0, 'srn'], null),
          controlFrameworks: policy.getIn(
            ['containedByControlFramework', 'items'],
            List()
          ).size,
          createdBy: policy.get('createdBy'),
          createdByUser: createdByUser,
        }
      })
      .toJS()

  handleSelectionChange = evnt => {
    const rows = evnt.api.getSelectedRows() || []
    this.setState({ checkboxSelectedRows: rows })
  }

  handleBulkDeletePolicies = () => {
    const policies = this.state.checkboxSelectedRows.map(item => item.policySrn)
    this.setState({
      checkboxSelectedRows: [],
    })
    this.props.bulkDeletePolicies(policies)
  }

  render() {
    const tableData = this.formatPolicies()

    const canEdit = this.props.userHasPermission({
      permissionName: 'edit.controlframeworks',
      resourceId: ({ org }) => `/org/${org}/`,
    })

    return (
      <div style={this.styles.container}>
        <div style={this.styles.navContextContainer}>
          <Breadcrumb>
            <BreadcrumbItem>
              <TextLink color="primary" to={{ pathname: '/App/ControlCenter' }}>
                All Control Frameworks
              </TextLink>
            </BreadcrumbItem>
            <BreadcrumbItem>All Policies</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div style={this.styles.titleContainer}>
          <div style={this.styles.pageTitle}>
            <FormattedMessage {...messages.allPolicyTitle} />
          </div>
          <div>
            <Button color="primary" onClick={this.openCreateModal}>
              <Icon fa name="plus" />
              &nbsp; Create Policy
            </Button>
          </div>
        </div>
        <BorderedCard style={this.styles.policyContainer}>
          <DataTable
            autosize={false}
            hasCheckBoxes={canEdit}
            pageSize={13}
            bulkActionWorking={this.props.bulkEditing}
            selectionChanged={canEdit ? this.handleSelectionChange : undefined}
            checkboxSelectedRows={
              canEdit ? this.state.checkboxSelectedRows : undefined
            }
            data={tableData}
            defaultColDef={{
              onCellDoubleClicked: this.handleCellClick,
            }}
            bulkActions={[
              <ToolbarItem
                key="delete"
                permissionName="edit.controlframeworks"
                iconName={'trash'}
                onClick={this.handleBulkDeletePolicies}
                disabled={this.state.checkboxSelectedRows.length === 0}
                color="#fff"
              >
                {this.state.checkboxSelectedRows.length === 1
                  ? 'Delete Policy'
                  : 'Delete Policies'}
              </ToolbarItem>,
            ]}
            customColumnConfig={{
              title: {
                minWidth: 300,
                width: 300,
              },
              policySrn: {
                hide: true,
              },
              searchSrn: {
                hide: true,
              },
              description: {
                flex: 1,
              },
              createdByUser: {
                hide: true,
              },
              createdBy: {
                minWidth: 200,
                width: 200,
                valueGetter: params => {
                  if (!params.data) {
                    return ''
                  }

                  return params.data.createdByUser.name || 'Sonrai'
                },
                cellRendererFramework: ({ data }) => {
                  if (!data) {
                    return null
                  }
                  return <CreatedByBadge table createdBy={data.createdBy} />
                },
              },
            }}
          />
        </BorderedCard>
        <CreatePolicyModal
          isOpen={this.state.createModalOpen}
          toggle={this.closeCreateModal}
          sonraiSearches={this.props.sonraiSearches}
          savedSearches={this.props.savedSearches}
          createPolicy={this.props.createPolicy}
          creating={this.props.creating}
        />
      </div>
    )
  }
}

PolicyList.propTypes = {
  policies: ImmutablePropTypes.map,
  sonraiUsers: ImmutablePropTypes.map.isRequired,
  theme: themeShape,
  savedSearches: ImmutablePropTypes.map,
  createPolicy: PropTypes.func,
  push: PropTypes.func,
  sonraiSearches: ImmutablePropTypes.list,
  creating: PropTypes.bool,
  userHasPermission: PropTypes.func.isRequired,
  bulkDeletePolicies: PropTypes.func.isRequired,
  bulkEditing: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  sonraiUsers: selectSonraiUsers,
  policies: selectPolicies,
  sonraiSearches: selectSonraiSearches,
  savedSearches: selectSavedSearches,
  creating: selectCreatingPolicy,
  bulkEditing: selectBulkEditing,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createPolicy,
      push,
      bulkDeletePolicies,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(themeable, withConnect)(permissionChecker(PolicyList))
