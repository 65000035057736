import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectControlCenterDomain = state => state.get('controlCenter') || Map()

export const selectCreatingControlGroup = createSelector(
  selectControlCenterDomain,
  state => state.get('creating', false)
)

export const selectBulkEditing = createSelector(
  selectControlCenterDomain,
  state => state.get('bulkEditing', Map())
)
