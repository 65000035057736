/*
 *
 * ControlCenter reducer
 *
 */

import { fromJS } from 'immutable'
import {
  BULK_DELETE_CONTROL_GROUPS,
  BULK_ENABLE_CONTROL_GROUPS,
  BULK_DISABLE_CONTROL_GROUPS,
  BULK_OPERATION_SUCCESS,
  BULK_DELETE_POLICIES,
} from './constants'
import {
  CREATE_CONTROL_GROUP,
  CREATE_CONTROL_GROUP_SUCCESS,
} from 'containers/ControlFrameworkData/constants'
import { handleActions } from 'redux-actions'

const initialState = fromJS({
  creating: false,
  bulkEditing: false,
})

const controlCenterReducer = handleActions(
  {
    [BULK_ENABLE_CONTROL_GROUPS]: state => state.set('bulkEditing', true),
    [BULK_DELETE_CONTROL_GROUPS]: state => state.set('bulkEditing', true),
    [BULK_DISABLE_CONTROL_GROUPS]: state => state.set('bulkEditing', true),
    [BULK_DELETE_POLICIES]: state => state.set('bulkEditing', true),
    [BULK_OPERATION_SUCCESS]: state => state.set('bulkEditing', false),
    [CREATE_CONTROL_GROUP]: state => state.set('creating', true),
    [CREATE_CONTROL_GROUP_SUCCESS]: state => state.set('creating', false),
  },
  initialState
)

export default controlCenterReducer
