/**
 *
 * ControlCenter
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import injectSaga from 'utils/injectSaga'
import injectReducer from 'utils/injectReducer'
import LoadingAnim from 'components/LoadingAnim'
import {
  selectLoadedPolicies,
  selectLoadedControlGroups,
} from 'containers/ControlFrameworkData/selectors'
import { selectSonraiUsersLoading } from 'containers/SonraiData/selectors'
import reducer from './reducer'
import sagas from './sagas'
import ControlFrameworksManager from './ControlFrameworksManager'
import PolicyList from './PolicyList'

export class ControlCenter extends React.Component {
  render() {
    if (
      !this.props.loadedPolicies ||
      !this.props.loadedControlGroups ||
      this.props.sonraiUsersLoading
    ) {
      return <LoadingAnim />
    }
    if (this.props.location.pathname === '/App/ControlCenter/ListPolicy') {
      return <PolicyList />
    }
    return (
      <div style={{ height: '100%', overflow: 'auto', padding: '1em' }}>
        <ControlFrameworksManager />
      </div>
    )
  }
}

ControlCenter.propTypes = {
  loadedPolicies: PropTypes.bool,
  loadedControlGroups: PropTypes.bool,
  location: PropTypes.object,
  sonraiUsersLoading: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  loadedControlGroups: selectLoadedControlGroups,
  loadedPolicies: selectLoadedPolicies,
  sonraiUsersLoading: selectSonraiUsersLoading,
})

const withConnect = connect(mapStateToProps)

const withReducer = injectReducer({ key: 'controlCenter', reducer })

const withSaga = injectSaga({
  key: 'controlCenter',
  saga: sagas,
})

export default compose(withReducer, withConnect, withSaga)(ControlCenter)
