/*
 *
 * ControlCenter constants
 *
 */

export const BULK_DISABLE_CONTROL_GROUPS =
  'app/ControlCenter/BULK_DISABLE_CONTROL_GROUP'
export const BULK_ENABLE_CONTROL_GROUPS =
  'app/ControlCenter/BULK_ENABLE_CONTROL_GROUP'
export const BULK_DELETE_CONTROL_GROUPS =
  'app/ControlCenter/BULK_DELETE_CONTROL_GROUP'
export const BULK_OPERATION_SUCCESS = 'app/ControlCenter/BULK_OPERATION_SUCCESS'

export const BULK_DELETE_POLICIES = 'app/ControlCenter/BULK_DELETE_POLICIES'
