import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'

import Icon from 'components/Icon'
import FormLabel from 'components/FormLabel'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import MarkdownEditor from 'components/MarkdownEditor'

import { selectCreatingControlGroup } from './selectors'
import { setActiveFramework } from 'containers/ControlFrameworkContentLibrary/actions'
import { createControlGroup } from 'containers/ControlFrameworkData/actions'
import messages from './messages'

export class AddControlGroupModal extends React.Component {
  styles = {
    tabbar: {
      paddingBottom: 0,
      fontWeight: '300',
    },
    modalBody: { maxHeight: '75vh', overflow: 'auto' },
  }

  state = {
    friendlyName: '',
    title: '',
    description: '',
  }

  componentDidUpdate(oldProps) {
    if (oldProps.creatingControlGroup && !this.props.creatingControlGroup) {
      this.props.toggle()
    }
  }

  setCardDescription = value => {
    this.setState({
      description: value,
    })
  }

  setCardTitle = e => {
    this.setState({
      title: e.target.value,
    })
  }

  setFriendlyName = e => {
    this.setState({
      friendlyName: e.target.value,
    })
  }

  handleAdd = () => {
    this.props.createControlGroup({
      title: this.state.title,
      description: this.state.description,
      friendlyName: this.state.friendlyName,
    })
  }

  renderCreateContent = () => {
    return (
      <Fragment>
        <FormLabel
          for="cgTitle"
          required
          label={<FormattedMessage {...messages.cGTitleLabel} />}
        />
        <Input
          type="text"
          name="cgTitle"
          id="cgTitle"
          value={this.state.title}
          onChange={this.setCardTitle}
        />

        <FormLabel
          for="cgFriendlyName"
          label={<FormattedMessage {...messages.cGFriendlyNameLabel} />}
        />
        <Input
          type="text"
          name="cgFriendlyName"
          id="cgFriendlyName"
          value={this.state.friendlyName}
          onChange={this.setFriendlyName}
        />

        <FormLabel
          for="cgDescription"
          label={<FormattedMessage {...messages.cGDescriptionLabel} />}
        />
        <MarkdownEditor
          name="cgDescription"
          value={this.state.description}
          onChange={this.setCardDescription}
        />
      </Fragment>
    )
  }

  render() {
    return (
      <Modal isOpen={true} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggle} style={this.styles.tabbar}>
          {this.props.intl.formatMessage(messages.createCGHeader)}
        </ModalHeader>
        <ModalBody style={this.styles.modalBody}>
          {this.renderCreateContent()}
        </ModalBody>
        <ModalFooter>
          <Fragment>
            <TextLink color="secondary" onClick={this.props.toggle}>
              <FormattedMessage {...messages.cancelButton} />
            </TextLink>
            <Button
              color="primary"
              onClick={this.handleAdd}
              disabled={!this.state.title || this.props.creatingControlGroup}
            >
              {this.props.creatingControlGroup ? (
                <Icon fa name="sync" spin />
              ) : (
                <FormattedMessage {...messages.createButton} />
              )}
            </Button>
          </Fragment>
        </ModalFooter>
      </Modal>
    )
  }
}

AddControlGroupModal.propTypes = {
  creatingControlGroup: PropTypes.bool,
  createControlGroup: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  intl: intlShape,
  setActiveFramework: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  creatingControlGroup: selectCreatingControlGroup,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createControlGroup,
      setActiveFramework,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, injectIntl)(AddControlGroupModal)
