import React from 'react'
import PropTypes from 'prop-types'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

const StartMessage = ({ children, theme, containerStyle = {} }) => {
  const styles = {
    container: {
      display: 'flex',
      paddingTop: '2em',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },

    message: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '1.5em',
      padding: '0 1em',
      color: theme.secondary,
    },
    arrowContainer: {
      width: '250px',
    },
  }

  return (
    <div style={{ ...styles.container, ...containerStyle }}>
      <div style={styles.message}>{children}</div>
      <div style={styles.arrowContainer}>
        <svg width="250" height="280">
          <path
            d="M 5 140 Q 170 150 200 5 "
            fill="transparent"
            stroke={theme.secondary}
            strokeWidth="5"
          />
          <path
            d="M 165 30 L 200 5 L 220 35 "
            fill="transparent"
            stroke={theme.secondary}
            strokeWidth="5"
          />
        </svg>
      </div>
    </div>
  )
}

StartMessage.propTypes = {
  children: PropTypes.node,
  containerStyle: PropTypes.object,
  theme: themeShape,
}

export default themeable(StartMessage)
