import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PolicyEditor from 'containers/PolicyEdit/PolicyEditor'
import TextLink from 'components/TextLink'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import {
  POLICY_EVAL_TYPES,
  POLICY_OP_TYPES,
} from 'containers/PolicyEdit/constants'

class CreatePolicyModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      policyEvalType: POLICY_EVAL_TYPES.COUNT,
      policyEvalTarget: 0,
      policyEvalOp: POLICY_OP_TYPES.GT,
      description: '',
      title: '',
      alertingLevelNumeric: 5,
      selectedSearchValue: null,
      selectedSearchSrn: null,
    }
  }

  componentDidUpdate(prevProps) {
    // if (this.props.error && !prevState.errorMsg) {
    //   this.setError(this.props.error)
    // }
    // after load is complete with no errors, close em up
    if (prevProps.creating && !this.props.creating) {
      this.props.toggle()
    }
  }

  setSelectedSearch = value => {
    if (!value) {
      this.setState({
        selectedSearchValue: null,
        selectedSearchSrn: null,
      })
    } else {
      this.setState({
        selectedSearchValue: value.value,
        selectedSearchSrn: value.srn,
      })
    }
  }

  setPolicyDescription = value => {
    this.setState({ description: value })
  }

  setPolicyTitle = params => this.setState({ title: params.target.value })

  onAlertingLevelNumericChange = alertLevel =>
    this.setState({ alertingLevelNumeric: alertLevel })

  setPolicyEvalOp = e => {
    this.setState({
      policyEvalOp: e.target.value,
    })
  }

  setPolicyEvalTarget = e => {
    const parsed = parseInt(e.target.value)
    this.setState({
      policyEvalTarget: isNaN(parsed) ? e.target.value : parsed,
    })
  }

  setPolicyEvalType = e => {
    this.setState({
      policyEvalType: e.target.value,
    })
  }

  createPolicy = () => {
    const evalCriteria = {
      [this.state.policyEvalOp]: [
        this.state.policyEvalType,
        this.state.policyEvalTarget,
      ],
    }

    this.props.createPolicy({
      title: this.state.title,
      description: this.state.description,
      evalCriteria: evalCriteria,
      alertingLevelNumeric: this.state.alertingLevelNumeric,
      searchId: this.state.selectedSearchSrn,
    })
  }

  render() {
    return (
      <Modal size="lg" isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader>Create Policy</ModalHeader>
        <ModalBody>
          <PolicyEditor
            policyEvalTarget={this.state.policyEvalTarget}
            policyEvalOp={this.state.policyEvalOp}
            policyEvalType={this.state.policyEvalType}
            description={this.state.description}
            title={this.state.title}
            savedSearches={this.props.savedSearches}
            sonraiSearches={this.props.sonraiSearches}
            alertingLevelNumeric={this.state.alertingLevelNumeric}
            selectedSearchValue={this.state.selectedSearchValue}
            setPolicyEvalTarget={this.setPolicyEvalTarget}
            setPolicyTitle={this.setPolicyTitle}
            setPolicyDescription={this.setPolicyDescription}
            onAlertingLevelNumericChange={this.onAlertingLevelNumericChange}
            setSelectedSearch={this.setSelectedSearch}
            setPolicyEvalOp={this.setPolicyEvalOp}
            setPolicyEvalType={this.setPolicyEvalType}
            loading={this.props.creating}
          />
        </ModalBody>
        <ModalFooter>
          <TextLink color="secondary" onClick={this.props.toggle}>
            Cancel
          </TextLink>
          <Button
            color="primary"
            disabled={this.props.creating}
            onClick={this.createPolicy}
          >
            {this.props.creating ? <Icon fa spin name="sync" /> : `Create`}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

CreatePolicyModal.propTypes = {
  createPolicy: PropTypes.func,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  savedSearches: ImmutablePropTypes.map,
  sonraiSearches: ImmutablePropTypes.list,
  creating: PropTypes.bool,
}
export default CreatePolicyModal
